import "./Footer.scss";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";

import logo from "../../Assets/brand.png";

interface FooterProps {
  children?: React.ReactNode;
}

const Footer: React.FC<FooterProps> = ({ children }) => {
  return (
    <div className="footer">
      <div className="footer__actions">
        <a href="https://ouner.mx" className="brand">
          <img src={logo} alt="Ouner Logo" />
        </a>

        <div className="footer__actions--pages">
          <h5>Más de Ouner</h5>
          <a href="https://ouner.mx">Conocer más sobre Ouner</a>
          <a href="/adelanto">Obtén un adelanto de renta</a>
          <a href="https://contratos.ouner.mx/">
            Genera tu contrato de arrendamiento gratis
          </a>
        </div>

        <div className="footer__actions--social-media">
          <a href="https://www.instagram.com/ounermx/">
            <InstagramIcon />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61559058394313">
            <FacebookIcon />
          </a>
        </div>
      </div>
      <div className="footer__disclaimer">
        <p>© 2024, Ouner</p>
      </div>
    </div>
  );
}

export default Footer;
