import "./Form.scss";
import { useState } from "react";
import { addDoc, collection } from "firebase/firestore";

import StepOne from "./Steps/One/OneStep"
import TwoStep from "./Steps/Two/TwoStep";
import ThreeStep from "./Steps/Three/ThreeStep";

import { db } from "../../../Services/FirebaseConfig";
import OunerFunctions from "../../../Services/OunerFunctions";

export interface IAdvancementData {
  user: {
    name: string;
    id: string;
    email: string;
    phone: string;
  };
  details: {
    rent: {
      value: number;
      formatted: string;
    };
    months: number;
  };
}

function AdvancementForm() {

  const [formValues, setFormValues] = useState<IAdvancementData>({
    user: {
      name: "",
      id: "",
      email: "",
      phone: "",
    },
    details: {
      rent: {
        value: 0,
        formatted: "",
      },
      months: 0,
    },
  });
  const [step, setStep] = useState(1);

  const handleDataChange = (data: any) => {
    const updatedFormValues = {
      ...formValues,
      ...data
    };

    setFormValues(updatedFormValues)
  };

  const handleSubmit = async () => {
    try {
      const collectionName = process.env.REACT_APP_ADVANCEMENT_COLLECTION ?? "";

      const firebaseRef = addDoc(collection(db, collectionName), {
        ...formValues,
      });

      const hubSpotRef = OunerFunctions.createHubSpotDeal(formValues);

      Promise.resolve([firebaseRef, hubSpotRef]);
    } catch (e) {
      console.log(e)
    }
    
    setStep(3);
  }

  return (
    <div className="advancement-form">
      {step === 1 && (
        <StepOne onDataChange={handleDataChange} nextStep={() => setStep(2)} />
      )}
      {step === 2 && (
        <TwoStep onDataChange={handleDataChange} nextStep={handleSubmit} />
      )}
      {step === 3 && (
        <ThreeStep />
      )}
    </div>
  );
}

export default AdvancementForm;
