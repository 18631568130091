import React from "react";
import "./Button.scss";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant:
    | "filled"
    | "outlined"
    | "text"
    | "elevated"
    | "tonal"
    | "elevated-icon";
  text: string;
  icon?: React.ReactNode;
};

const Button: React.FC<ButtonProps> = ({
  text,
  variant,
  onClick,
  icon,
  disabled,
  ...props
}) => {
  return (
    <button
      className={`button ${icon ? "button--icon" : ""} button--${variant}`}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {icon && <span className="icon">{icon}</span>}
      <span className="text">{text}</span>
    </button>
  );
};

export default Button;
