import "./Advancement.scss";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import HandshakeIcon from '@mui/icons-material/Handshake';
import AddTaskIcon from '@mui/icons-material/AddTask';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import EmergencySharpIcon from '@mui/icons-material/EmergencySharp';

import AppBar from "../../ui/AppBar/AppBar";
import Button from "../../ui/Button/Button";
import Footer from "../../ui/Footer/Footer";

import ImgMainOne from "../../Assets/Advancement/MainOne.jpg"
import ImgMainTwo from "../../Assets/Advancement/MainTwo.jpg";
import ImgMainThree from "../../Assets/Advancement/MainThree.jpg";
import ImgFormBackground from "../../Assets/Advancement/FormBackground.jpg";

import AdvancementForm from "../../Components/Advancement/Form/Form";

function AdvancementPage() {
  const navigate = useNavigate();

  const questions = [
    {
      title: "¿Qué es el adelanto de renta?",
      text: "El adelanto de renta es un servicio para propietarios con contratos de arrendamiento activos. Te permite recibir un pago anticipado de tus futuras rentas con un pequeño descuento, dándote acceso inmediato a liquidez.",
    },
    {
      title:
        "¿Qué tipo de propiedades son elegibles para el adelanto de rentas?",
      text: "Ouner se especializa exclusivamente en propiedades de vivienda. Esto incluye casas y departamentos que tengan un contrato de arrendamiento vigente.",
    },
    {
      title: "¿Cómo se determina cuánto puedo adelantar?",
      text: "El monto del adelanto se basa en el valor mensual de la renta y la duración del contrato de arrendamiento. Puedes obtener una estimación personalizada a través de nuestra plataforma, proporcionando algunos detalles clave sobre tu propiedad y el contrato actual.",
    },
    {
      title: "¿Necesito la autorización del inquilino?",
      text: "No. Ouner adquiere los derechos de los pagos futuros de la renta, pero esto no afecta el contrato de arrendamiento entre el propietario y el inquilino.",
    },
    {
      title: "¿Cuánto tiempo tarda la aprobación del adelanto de renta?",
      text: "El proceso de aprobación de un adelanto de renta puede tomar entre 24 y 72 horas, dependiendo de la rapidez con la que se proporcionen los documentos necesarios y se evalúe el contrato de arrendamiento. Una vez aprobado, los fondos se transfieren de inmediato.",
    },
  ];

  const formSectionRef = useRef<HTMLDivElement | null>(null);
  
  const handleScrollToForm = () => {
    if (formSectionRef.current) {
      formSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <AppBar>
        <Button
          text="Simula tu adelanto ahora"
          variant="filled"
          onClick={handleScrollToForm}
        />
      </AppBar>

      <div className="advancement">
        <div className="advancement__hero">
          <div className="column">
            <div className="column--texts">
              <h2>Liquidez inmediata, hasta 3 años de renta por adelantado</h2>
              <p>
                Con Ouner, recibe hasta 3 años de renta por adelantado en menos
                de 48 horas, sin deudas ni riesgos. Simplifica tu vida, nosotros
                nos encargamos del resto
              </p>
            </div>
            <div className="column--actions">
              <Button
                text="Simula tu adelanto ahora"
                variant="filled"
                onClick={handleScrollToForm}
              />
              <Button
                text="Conocer más de Ouner"
                variant="text"
                icon={<ArrowForwardIcon />}
                onClick={() => navigate("/")}
              />
            </div>
          </div>
          <div className="column">
            <div className="column--image">
              <div className="image-column-one">
                <img src={ImgMainOne} alt="Ouner Logo" />
                <img src={ImgMainTwo} alt="Ouner Logo" />
              </div>
              <div className="image-column-two">
                <img src={ImgMainThree} alt="Ouner Logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="advancement__benefits">
          <h3>Ventajas del adelanto de renta</h3>

          <div className="advancement__benefits__items">
            <div className="advancement__benefits__items__card">
              <div className="advancement__benefits__items__icon">
                <PhoneAndroidIcon />
              </div>
              <p className="advancement__benefits__items__content">
                100% online, fácil y rápido
              </p>
            </div>
            <div className="advancement__benefits__items__card advancement__benefits__items__card--dark">
              {" "}
              {/* Cambié el orden de las clases */}
              <div className="advancement__benefits__items__icon">
                <AttachMoneyIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Te adelantamos hasta 3 años de renta
              </p>
            </div>
            <div className="advancement__benefits__items__card">
              <div className="advancement__benefits__items__icon">
                <EmergencySharpIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Asumimos los riesgos por ti
              </p>
            </div>
            <div className="advancement__benefits__items__card advancement__benefits__items__card--dark">
              <div className="advancement__benefits__items__icon">
                <HandshakeIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Gestionamos la renta por completo
              </p>
            </div>
            <div className="advancement__benefits__items__card">
              <div className="advancement__benefits__items__icon">
                <AddTaskIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Sin deudas ni complicaciones
              </p>
            </div>
            <div className="advancement__benefits__items__card advancement__benefits__items__card--dark">
              <div className="advancement__benefits__items__icon">
                <QueryBuilderIcon />
              </div>
              <p className="advancement__benefits__items__content">
                Aprobación en solo 48 horas
              </p>
            </div>
          </div>
        </div>

        <div className="advancement__form" ref={formSectionRef}>
          <img src={ImgFormBackground} alt="Ouner Logo" />
          <AdvancementForm />
        </div>

        <div className="advancement__questions">
          <div className="column column--40">
            <h3>Preguntas frecuentes</h3>
            <p>
              Todo lo que necesitas saber sobre el producto y la facturación.
              ¿No encuentras la respuesta que buscas? Por favor,{" "}
              <a
                href="https://wa.me/message/42HEWEITMKLEK1"
                target="_blank"
                rel="noopener noreferrer"
              >
                chatea con nuestro equipo
              </a>
            </p>
          </div>
          <div className="column">
            <div className="questions">
              {questions.map((question, index) => (
                <div key={index} className="question">
                  <h6 className="question__title">{question.title}</h6>
                  <p className="question__text">{question.text}</p>
                  <div className="divider" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
}

export default AdvancementPage;
